import { setAllServiceCategoryFailed, setAllServiceCategorySuccess } from './services-categories.actions';
import { ServiceCategoryInterface } from '@models/service/service-category.interface';
import { createReducer, on } from '@ngrx/store';

export const servicesCategoriesFeatureKey = 'servicesCategories';

export type ServicesCategoriesState = ServiceCategoryInterface[];

const initialState: ServicesCategoriesState = [] as ServiceCategoryInterface[];

export const servicesCategoriesReducer = createReducer(
  initialState,
  on(setAllServiceCategorySuccess, (_state, { servicesCategories }) => {
    return servicesCategories;
  }),
  on(setAllServiceCategoryFailed, (_state) => {
    return [];
  })
);
